import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserData, updateUserData, chargeClient } from '../../actions/admin';
import { setAlert } from '../../actions/alert';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import {
  DataGrid,
  useGridApiContext,
  GridActionsCellItem
} from '@mui/x-data-grid';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InventoryIcon from '@mui/icons-material/Inventory';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function SelectEditInputCell(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ height: 1 }}
      native
      autoFocus
    >
      <option>ACCELERATE IQ</option>
      <option>ACTIVATE IQ</option>
      <option>BOOST IQ</option>
      <option>DEMO IQ</option>
    </Select>
  );
}

const renderSelectEditInputCell = (params) => {
  return <SelectEditInputCell {...params} />;
};

const AdminDashboard = ({
  getUserData, updateUserData, userData, setAlert, chargeClient,
}) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientEmail, setClientEmail] = useState('');

  const currentDate = dayjs();

  const [dateValue, setDateValue] = useState(currentDate);
  const [totalCost, setTotalCost] = useState(0);
  const [totalCharge, setTotalCharge] = useState(0);
  const [verified, setVerified] = useState('');

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onChange = (e) => setTotalCharge(e.target.value);

  const onSubmit = async (e) => {
    if (verified !== 'Verified') setAlert('This customer should verify the payment method first.', 'danger');
    else if (totalCharge === 0) setAlert('You cannot set up an invoice with a cost of $0.', 'danger');
    else {
      try {
        setLoading(true);
        await chargeClient(clientEmail, totalCharge, dateValue, true);
        handleClose();  
      } catch (err) {
        setAlert('Error charing the client. Please try again.', 'danger');
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    const date = dayjs().format('YYYY-MM-DD');

    document.title = 'Prospect IQ | Dashboard';
    getUserData(date);
  }, []);

  const calculateTotalCost = () => {
    let sum = 0;
    userData.forEach(data => {
      sum += data.totalCost;
    });
    setTotalCost(sum);
  }

  useEffect(() => {
    setRows(userData);

    console.log('here');
    calculateTotalCost();
  }, [userData]);

  const handleProcessRowUpdateError = () => {
    setAlert('We cannot save the changes', 'danger');
  };
  const processRowUpdate = async (updatedRow) => {
    await updateUserData(updatedRow, dateValue);

    console.log('updated', updatedRow);

    const data = {
      id: updatedRow.id,
      fname: updatedRow.fname,
      lname: updatedRow.lname,
      email: updatedRow.email,
      plan: updatedRow.plan,
      dials: updatedRow.dials,
      reaches: updatedRow.reaches,
      booked: updatedRow.booked,
      costPerReach: updatedRow.costPerReach,
      costPerBooked: updatedRow.costPerBooked,
    }
    calculateTotalCost();
    return data;
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'fullName',
      headerName: 'Full Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 150,
      valueGetter: (params) =>
        `${params.row.fname || ''} ${params.row.lname || ''}`,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      editable: false,
    },
    {
      field: 'paymentVerified',
      headerName: "Payment Method",
      width: 120,
      editable: false,
    },
    {
      field: 'discountPricing',
      headerName: "Discount Prcing",
      width: 120,
      editable: false,
    },
    {
      field: 'discountFee',
      headerName: "Discount Fee",
      width: 120,
      editable: false,
    },
    {
      field: 'plan',
      headerName: 'Plan',
      renderEditCell: renderSelectEditInputCell,
      width: 200,
      editable: true,
    },
    // {
    //   field: 'totalCost',
    //   headerName: "Total to be Charged",
    //   type: 'number',
    //   sortable: true,
    //   width: 140,
    //   editable: false,
    // },
    // {
    //   field: 'dials',
    //   headerName: 'Dials',
    //   type: 'number',
    //   width: 110,
    //   editable: true,
    // },
    // {
    //   field: 'reaches',
    //   headerName: 'Reaches',
    //   type: 'number',
    //   width: 110,
    //   editable: true,
    // },
    // {
    //   field: 'booked',
    //   headerName: 'Booked',
    //   type: 'number',
    //   width: 110,
    //   editable: true,
    // },
    // {
    //   field: 'costPerReach',
    //   headerName: 'Cost Per Reach',
    //   type: 'number',
    //   width: 130,
    //   editable: true,
    // },
    // {
    //   field: 'costPerBooked',
    //   headerName: 'Cost Per Book',
    //   type: 'number',
    //   width: 130,
    //   editable: true,
    // },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: 'Order Charge',
    //   width: 120,
    //   cellClassName: 'MuiDataGrid-pinnedColumns--left',
    //   getActions: ({ id }) => {
    //     return [
    //       <GridActionsCellItem
    //         icon={<InventoryIcon />}
    //         label="Charge"
    //         className="textPrimary"
    //         onClick={() => {
    //           setTotalCharge(rows[id - 1].totalCost);
    //           setClientEmail(rows[id - 1].email);
    //           setVerified(rows[id - 1].paymentVerified);
    //           handleOpen()
    //         }}
    //         color="inherit"
    //       />,
    //     ];
    //   }
    // }
  ];

  return (
    <section className="landing">
      <main className="main p-subscribe">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            { loading ? (
                <div style = {{ textAlign: 'center' }}>
                  <CircularProgress />
                  <p>Charging...</p>
                </div>
              ) : (<form className="c-form" autoComplete="off">
                <div className="c-form__field">
                  <p>
                    {/* This client will be charged ${totalCharge}. */}
                    Registered users
                    <br /> <br />
                  </p>
                  <label className="c-form__label" htmlFor="email">Total Cost to be charged</label>
                  <input className="c-form__input" type="string" name="codeFee" id="codeFee" placeholder="Type your discount code for implementation fee here..." value={totalCharge} onChange={onChange} />
                </div>
                <div className="c-form__field c-form__field--btn">
                  <div className="c-btn__wrapper">
                    <input className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" type="submit" onClick = {onSubmit} value="Submit" />
                  </div>
                </div>
              </form>)}
            </Typography>
          </Box>
        </Modal>

        <section className="subscribe" id="subscribe">
          <div className="c-grid">
            <div className="section__wrapper">
              <div className="c-section" data-align="center" data-size="exbig">
                <div className="c-section__head">
                  <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                          label="Please choose the date."
                          value={dateValue}
                          onChange={(newValue) => {
                            setDateValue(newValue);
                            const date = newValue.format('YYYY-MM-DD');
                            getUserData(date);
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <span style={{ marginRight: '12px', marginTop: '8px' }}><p style={{ fontSize: '20px' }}>
                      {/* Total to be charged: <strong>${totalCost}</strong> */}
                      Registered users
                    </p></span>
                  </div>
                </div>
                <div className="c-section__body">
                  <div>
                    <Box sx={{ height: 500, backgroundColor: 'white' }}>
                      <DataGrid
                        rows={rows.map((item, index) => ({ id: index + 1, ...item }))}
                        columns={columns}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </section>
  );
}


AdminDashboard.propTypes = {
  getUserData: PropTypes.func.isRequired,
  updateUserData: PropTypes.func.isRequired,
  chargeClient: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  userData: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  userData: state.admin.userData,
  userRow: state.admin.userRow,
});

export default connect(mapStateToProps, { getUserData, updateUserData, setAlert, chargeClient })(AdminDashboard);
