import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { payment } from '../../actions/payment';
import { setAlert } from '../../actions/alert';
import { submitPricingDiscount } from '../../actions/auth';

const MaxSpend = ({
  setAlert, auth: { user }, payment, paymentTitle, submitPricingDiscount
}) => {

  const [formData, setFormData] = useState({
    cost: '',
    discountPricing: '',
  });

  const { cost, discountPricing } = formData;
  const [limitValue, setLimitValue] = useState(0);

  const [showMaxspendFlg, setShowMaxspendFlg] = useState(false);

  useEffect(() => {
    document.title = 'Prospect IQ | Value Check';
    // if (paymentTitle === "IQ Outsourcing") setLimitValue(3000);
    // if (paymentTitle.startsWith("BOOST IQ")) {
    //   setLimitValue(0);
    //   setShowMaxspendFlg(true);
    // }
    // if (paymentTitle.startsWith("ACTIVATE IQ")) {
    //   setLimitValue(0);
    //   setShowMaxspendFlg(true);
    // }
    // if (paymentTitle === "IQ Accelerate") setLimitValue(1500);
    // if (paymentTitle.startsWith("IQ Scale")) setLimitValue(2000);
  }, []);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    let realTitle = user.planNo;
    if (paymentTitle !== '') realTitle = paymentTitle;
    if (showMaxspendFlg === true) {
      if (parseInt(cost) >= limitValue && realTitle !== '') {
        if (discountPricing !== '') {
          await submitPricingDiscount(user.email, discountPricing);
        }
        payment(realTitle, cost);
      }
      else if (parseInt(cost) < limitValue) setAlert(`The value should be at least ${limitValue}.`, 'danger');
      else setAlert('Please select a plan on the dashboard.', 'danger');

    }
    else {
      if (realTitle !== '') {
        if (discountPricing !== '') {
          await submitPricingDiscount(user.email, discountPricing);
        }
        payment(realTitle);
      }
    }
  };

  return (
    <section className="landing">
      <main className="main p-subscribe">
        <section class="thank-you" id="thank-you">
          <div class="c-grid">
            <div class="section__wrapper">
              <div class="c-section" data-align="center" data-size="">
                <div class="c-section__head">
                  <div class="logo logo--header"><a class="logo__link" href="/"><img class="logo__image" src="../img/logo-2.png" srcSet="../img/logo-2@2x.png 2x" alt="Logotype" /></a></div>
                </div>
                <div class="c-section__body">
                  <p>
                    {/* If you have an Optional AE Code 2 provided by ProspectIQ to discount the plan cost, please enter it.<br /><br /> */}
                    If you have an Optional AE Code provided by ProspectIQ to discount the plan cost, please enter it.<br /><br />
                  </p>
                  <form class="c-form" autocomplete="off" onSubmit={onSubmit}>
                    <div className="c-form__field">
                      {/* <label className="c-form__label" for="discountPricing">Optional AE Code 2</label> */}
                      <label className="c-form__label" for="discountPricing">Optional AE Code</label>
                      <input className="c-form__input" type="string" name="discountPricing" id="discountPricing" placeholder="Please input your Optional AE Code" value={discountPricing} onChange={onChange} />
                    </div>
                    {/* <div className="c-form__field">
                      <label className="c-form__label" for="cost">Maximum Spend</label>
                      <input
                        className="c-form__input"
                        type="string"
                        name="cost"
                        id="cost"
                        placeholder={showMaxspendFlg === true ? "e.g. 3000" : "This option is available only for BOOST IQ"}
                        value={cost}
                        onChange={onChange}
                        disabled={showMaxspendFlg===false}
                        required
                        />
                    </div> */}
                    <div className="c-form__field c-form__field--btn">
                      <div className="c-btn__wrapper">
                        <input type='button' className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" type="submit" value="Continue" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </section>
  );
};

MaxSpend.propTypes = {
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
  submitPricingDiscount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  paymentTitle: state.auth.paymentTitle,
});

export default connect(mapStateToProps, { setAlert, payment, submitPricingDiscount })(MaxSpend);
