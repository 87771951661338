import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendResetEmail } from '../../actions/auth';

const BACKEND_URL = 'https://backend.prospectiq.ai/api';

const ForgotPassword = ({ sendResetEmail, isAuthenticated, isVerified }) => {
  const [formData, setFormData] = useState({
    email: ''
  });
  const [message, setMessage] = useState('')

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    fetch(`${BACKEND_URL}/auth/forgot-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
    }).then(response => {
        if (response.status === 200) {
            setMessage('Password reset email has been sent!');
        } else if (response.status === 400) {
            setMessage('Email not found!');
        } else {
            setMessage('Email not found!');
        }
    })
  };

  document.title = 'ProspectIQ | Forgot Password'

  if (isAuthenticated === true && isVerified === true) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section className="landing">
      <main className="main main--start p-sign-in">
        <section className="sign" id="sign">
          <div className="c-grid" style={{ minWidth: 'fit-content', width: '40%' }}>
            <div className="section__wrapper">
              <div className="c-section" data-align="center">
                <div className="c-section__body">
                  <h5 className="c-section__title">Reset your password</h5>
                  <form className="c-form" autoComplete="off" onSubmit={onSubmit}>
                    <div className="c-form__field">
                      <label className="c-form__label" htmlFor="email">Email</label>
                      <input className="c-form__input" type="email" name="email" id="email" placeholder="Type your email address..." value={email} onChange={onChange} required />
                    </div>
                    <div className="c-form__field c-form__field--link">{message}</div>
                    <div className="c-form__field c-form__field--btn">
                      <div className="c-btn__wrapper"><input className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" type="submit" value="Send password reset email" /></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer" id="footer">
        <div className="c-grid">
          <div className="footer__wrapper"></div>
        </div>
      </footer>
    </section>
  );
};

ForgotPassword.propTypes = {
  sendResetEmail: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isVerified: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVerified: state.auth.isVerified
});

export default connect(mapStateToProps, { sendResetEmail })(ForgotPassword);
