import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCustomerData, updateCustomerData, chargeClient } from '../../actions/admin';
import { setAlert } from '../../actions/alert';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import {
  DataGrid,
  GridActionsCellItem
} from '@mui/x-data-grid';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InventoryIcon from '@mui/icons-material/Inventory';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const AdminCustomerTable = ({
  getCustomerData, updateCustomerData, customerData, setAlert, chargeClient,
}) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customerEmail, setCustomerEmail] = useState('');

  const currentDate = dayjs();

  const [dateValue, setDateValue] = useState(currentDate);
  const [totalCost, setTotalCost] = useState(0);
  const [totalCharge, setTotalCharge] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = async (e) => {
    if (totalCharge === 0) setAlert('You cannot set up an invoice with a cost of $0.', 'danger');
    else {
      try {
        setLoading(true);
        await chargeClient(customerEmail, totalCharge, dateValue, false);
        handleClose();
      } catch (err) {
        setAlert('Error charing the client. Please try again.', 'danger');
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    const date = dayjs().format('YYYY-MM-DD');

    document.title = 'Prospect IQ | Dashboard';
    getCustomerData(date);
  }, []);

  const calculateTotalCost = () => {
    let sum = 0;
    customerData.forEach(data => {
      sum += data.totalCost - data.discount;
    });
    setTotalCost(sum);
  }

  useEffect(() => {
    setRows(customerData);
    calculateTotalCost();
  }, [customerData]);

  const handleProcessRowUpdateError = () => {
    setAlert('We cannot save the changes', 'danger');
  };
  const processRowUpdate = async (updatedRow) => {
    if (updatedRow.totalCost >= updatedRow.discount) {
    //   setAlert('Total cost should be bigger than discount', 'danger');
    // }
    // else {
      await updateCustomerData(updatedRow, dateValue);

      const data = {
        id: updatedRow.id,
        name: updatedRow.name,
        email: updatedRow.email,
        // reaches: updatedRow.reaches,
        // booked: updatedRow.booked,
        // leadProcessing: updatedRow.leadProcessing,
        discount: updatedRow.discount,
        description: updatedRow.description,
        totalCost: updatedRow.totalCost,
        totalCharged: updatedRow.totalCharged,
      }

      calculateTotalCost();
      return data;
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'name',
      headerName: 'Customer Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
    },
    {
      field: 'isCharged',
      headerName: 'Status',
      sortable: false,
      width: 140,
      editable: false,
    },
    {
      field: 'totalCost',
      headerName: "Total to be Charged",
      type: 'number',
      sortable: true,
      width: 140,
      editable: true,
    },
    // {
    //   field: 'booked',
    //   headerName: "Booked",
    //   type: 'number',
    //   sortable: false,
    //   width: 140,
    //   editable: true,
    // },
    // {
    //   field: 'reaches',
    //   headerName: "Reaches",
    //   type: 'number',
    //   sortable: false,
    //   width: 140,
    //   editable: true,
    // },
    // {
    //   field: 'leadProcessing',
    //   headerName: "Lead Processing",
    //   type: 'number',
    //   sortable: false,
    //   width: 140,
    //   editable: true,
    // },
    {
      field: 'description',
      headerName: "Description",
      type: 'string',
      sortable: false,
      width: 250,
      editable: true,
    },
    {
      field: 'discount',
      headerName: "Discount",
      type: 'number',
      sortable: false,
      width: 140,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Order Charge',
      width: 120,
      cellClassName: 'MuiDataGrid-pinnedColumns--left',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<InventoryIcon />}
            label="Charge"
            className="textPrimary"
            onClick={() => {
              setTotalCharge(rows[id - 1].totalCost - rows[id - 1].discount);
              setCustomerEmail(rows[id - 1].email);
              handleOpen()
            }}
            color="inherit"
          />,
        ];
      }
    }
  ];

  return (
    <section className="landing">
      <main className="main p-subscribe">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              { loading ? (
                <div style = {{ textAlign: 'center' }}>
                  <CircularProgress />
                  <p>Charging...</p>
                </div>
              ) : (<form className="c-form" autoComplete="off">
                <div className="c-form__field">
                  <p>
                    This customer will be charged ${totalCharge}.
                    <br /> <br />
                  </p>
                </div>
                <div className="c-form__field c-form__field--btn">
                  <div className="c-btn__wrapper">
                    <input className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" type="submit" onClick = {onSubmit} value="Submit" />
                  </div>
                </div>
              </form>)}
            </Typography>
          </Box>
        </Modal>

        <section className="subscribe" id="subscribe">
          <div className="c-grid">
            <div className="section__wrapper">
              <div className="c-section" data-align="center" data-size="exbig">
                <div className="c-section__head">
                  <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                          label="Please choose the date."
                          value={dateValue}
                          onChange={(newValue) => {
                            setDateValue(newValue);
                            const date = newValue.format('YYYY-MM-DD');
                            getCustomerData(date);
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <span style={{ marginRight: '12px', marginTop: '8px' }}><p style={{ fontSize: '20px' }}>Total to be charged: <strong>${totalCost}</strong></p></span>
                  </div>
                </div>
                <div className="c-section__body">
                  <div>
                    <Box sx={{ height: 500, backgroundColor: 'white' }}>
                      <DataGrid
                        rows={rows.map((item, index) => ({ id: index + 1, ...item }))}
                        columns={columns}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </section>
  );
}


AdminCustomerTable.propTypes = {
  getCustomerData: PropTypes.func.isRequired,
  updateCustomerData: PropTypes.func.isRequired,
  chargeClient: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  customerData: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  customerData: state.admin.customerData,
  customerRow: state.admin.customerRow,
});

export default connect(mapStateToProps, { getCustomerData, updateCustomerData, setAlert, chargeClient })(AdminCustomerTable);
