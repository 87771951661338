import api from '../utils/api';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  VERIFY_SUCCESS,
  VERIFY_FAIL,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CUSTOMER_LOADED,
  ADMIN_LOADED,
} from './types';

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get('/auth');

    if (res.data.is_verified === true) {
      dispatch({
        type: VERIFY_SUCCESS,
      });
    }
    else {
      dispatch({
        type: VERIFY_FAIL,
      });
    }

    if (res.data.role === 'admin') {
      dispatch({
        type: ADMIN_LOADED,
        payload: res.data
      });
    } else {
      dispatch({
        type: CUSTOMER_LOADED,
        payload: res.data
      });
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

export const submitFeeDiscount = (email, discountFee) => async (dispatch) => {
  try {
    const req = {
      email: email,
      discountFee: discountFee,
    }
    await api.post('/users/discountFee', req);

    await dispatch(loadUser());

    return true;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
  return false;
};

export const submitPricingDiscount = (email, discountPricing) => async (dispatch) => {
  try {
    const req = {
      email: email,
      discountPricing: discountPricing,
    }
    await api.post('/users/discountPricing', req);

    await dispatch(loadUser());

    return true;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
  return false;
};

export const updateLicenseAgreement = (email, licenseAgreement) => async (dispatch) => {
  try {
    const req = {
      email: email,
      licenseAgreement: licenseAgreement
    }

    await api.post('/users/licenseAgreement', req);

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
}

export const updateTermCondition = (email, termCondition) => async(dispatch) => {
  try {
    const req = {
      email: email,
      termCondition: termCondition
    }

    await api.post('/users/termCondition', req);

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
}

// Register User
export const register = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/users', formData);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

export const verify = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/verify', formData);

    dispatch({
      type: VERIFY_SUCCESS,
      payload: res.data
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: VERIFY_FAIL
    });
  }
}

// Login User
export const login = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post('/auth', body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    dispatch(loadUser());

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Send password reset for user
export const sendResetEmail = (email) => async (dispatch) => {
  const body = { email };

  try {
    await api.post('/auth/forgot-password', body);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

// Logout
export const logout = () => ({ type: LOGOUT });
