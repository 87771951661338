import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { verify } from '../../actions/auth';
import PropTypes from 'prop-types';

const VerificationCode = ({ verify, isAuthenticated, isVerified }) => {
  const [formData, setFormData] = useState({
    verificationCode: '',
  });

  const { verificationCode } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    verify({ verificationCode });
  };

  if (isVerified === true) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section class="check-your-email" id="check-your-email">
        <div class="c-grid" style={{ width: 'fit-content' }}>
          <div class="section__wrapper">
            <div class="c-section" data-align="center">
              <div class="c-section__head">
                <div class="logo logo--header"><a class="logo__link" href="/"><img class="logo__image" src="./img/logo-2.png" srcset="./img/logo-2@2x.png 2x" alt="Logotype" /></a></div>
              </div>
              <div class="c-section__body">
                <h5 class="c-section__title">Check your email</h5>
                <p class="c-section__desc">Please, enter the verification code we sent to <br /><strong>your email</strong></p>
                <form class="c-form" onSubmit={onSubmit}>
                  <div class="c-form__field">
                    <label class="c-form__label" for="verificationCode">Confirmation code</label>
                    <input class="c-form__input" type="text" name="verificationCode" id="verificationCode" placeholder="Type your confirmation code..." onChange={onChange} required />
                  </div>
                  <div class="c-form__field c-form__field--btn">
                    <div class="c-btn__wrapper c-btn__wrapper--between"><a class="c-btn" href="#" data-btn-color="gray" data-btn-size="full" data-btn-fw="600" data-btn-round="half">Resend code</a><input type="submit" class="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" /></div>
                  </div>
                </form>
              </div>
              <div class="c-section__footer">
                <p class="c-section__info">Having trouble? Contact us at <a href='#'>support@prospectiq.ai</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

VerificationCode.propTypes = {
  setAlert: PropTypes.func.isRequired,
  verify: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isVerified: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVerified: state.auth.isVerified
});

export default connect(mapStateToProps, { setAlert, verify })(VerificationCode);
