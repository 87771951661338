import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendResetEmail } from '../../actions/auth';
import { setAlert } from '../../actions/alert';

const BACKEND_URL = 'https://backend.prospectiq.ai/api';

const ResetPassword = ({ sendResetEmail, setAlert, isAuthenticated, isVerified }) => {
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: '',
    });
    const [message, setMessage] = useState('')

    const { newPassword, confirmPassword } = formData;

    let { token } = useParams();
    console.log('token', token)

    const handleSendResetPassword = (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setAlert('Password does not match');
            return;
        }
        fetch(`${BACKEND_URL}/auth/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token, newPassword })
        }).then(response => {
            if (response.status === 200) {
                setMessage('Password has been reset successfully');
                window.location.href = '/login';
            } else if (response.status === 400) {
                setMessage('Invalid token or token expired');
            } else {
                setMessage('Something went wrong');
            }
        })
    }
    document.title = 'ProspectIQ | Reset Password'

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    if (isAuthenticated === true && isVerified === true) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <section className="landing">
            <main className="main main--start p-sign-in">
                <section className="sign" id="sign">
                    <div className="c-grid" style={{ minWidth: 'fit-content', width: '40%' }}>
                        <div className="section__wrapper">
                            <div className="c-section" data-align="center">
                                <div className="c-section__body">
                                    <h5 className="c-section__title">Reset your password</h5>
                                    <form className="c-form" autoComplete="off" onSubmit={handleSendResetPassword}>
                                        <div className="c-form__field">
                                            <label className="c-form__label" htmlFor="newPassword">New Password</label>
                                            <input className="c-form__input" type="password" name="newPassword" id="newPassword" placeholder="Type your new password" value={newPassword} onChange={onChange} required />
                                        </div>
                                        <div className="c-form__field">
                                            <label className="c-form__label" htmlFor="confirmPassword">Confirm Password</label>
                                            <input className="c-form__input" type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm your password" value={confirmPassword} onChange={onChange} required />
                                        </div>
                                        <div className="c-form__field c-form__field--link">{message}</div>
                                        <div className="c-form__field c-form__field--btn">
                                            <div className="c-btn__wrapper"><input className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" type="submit" value="Reset Password" /></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className="footer" id="footer">
                <div className="c-grid">
                    <div className="footer__wrapper"></div>
                </div>
            </footer>
        </section>
    );
};

ResetPassword.propTypes = {
    sendResetEmail: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isVerified: PropTypes.bool,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isVerified: state.auth.isVerified
});

export default connect(mapStateToProps, { sendResetEmail, setAlert })(ResetPassword);
