export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAIL = 'VERIFY_FAIL';
export const CUSTOMER_LOADED = 'CUSTOMER_LOADED';
export const ADMIN_LOADED = 'ADMIN_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const NO_REPOS = 'NO_REPOS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_TITLE = 'PAYMENT_TITLE';
export const PAYMENT_FAIL = 'PAYMENT_FAIL';
export const VerifyToLogin = "VerifyToLogin";
export const GETUSERDATA_SUCCESS = 'GETUSERDATA_SUCCESS';
export const GETUSERDATA_FAIL = 'GETUSERDATA_FAIL';
export const UPDATEUSERDATA_SUCCESS = 'UPDATEUSERDATA_SUCCESS';
export const UPDATEUSERDATA_FAIL = "UPDATEUSERDATA_FAIL";
export const DISCOUNT_CODE_SUCCESS = "DISCOUNT_CODE_SUCCESS";
export const DISCOUNT_CODE_FAIL = "DISCOUNT_CODE_FAIL";
export const DISCOUNT_SUCCESS = "DISCOUNT_SUCCESS";
export const DISCOUNT_FAIL = "DISCOUNT_FAIL";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";
export const TERMS_SUCCESS = "TERMS_SUCCESS";
export const LICENSE_SUCCESS = "LICENSE_SUCCESS";
export const PAYMENT_COST = "PAYMENT_COST";
export const GET_CUSTOMERDATA_SUCCESS = 'GET_CUSTOMERDATA_SUCCESS';
export const GET_CUSTOMERDATA_FAIL = 'GET_CUSTOMERDATA_FAIL';
export const UPDATE_CUSTOMERDATA_SUCCESS = 'UPDATE_CUSTOMERDATA_SUCCESS';
export const UPDATE_CUSTOMERDATA_FAIL = 'UPDATE_CUSTOMERDATA_FAIL';
