import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles'
import MUIRichTextEditor from 'mui-rte';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML, convertToRaw,ContentState } from 'draft-js';

import { updateTermContent, getTermContent } from '../../actions/admin';
import { setAlert } from '../../actions/alert';

const Terms = ({
    auth: { isAdmin }, updateTermContent, getTermContent, terms, setAlert
}) => {
    useEffect(() => { document.title = 'Prospect IQ | Terms & Conditions'; getTermContent() }, []);

    const [termContent, setTermContent] = useState("");

    const myTheme = createTheme({
        // Set up your custom MUI theme here
    });
    const sample = '<h1>Hello, there!</h1><p>this is test</p>';
    const contentHTML1 = convertFromHTML(sample);
    const state = ContentState.createFromBlockArray(
        contentHTML1.contentBlocks,
        contentHTML1.entityMap,
    );
    const [content, setContent] = useState(JSON.stringify(convertToRaw(state)));

    useEffect(() => {
        const sample1 = terms;
        const contentHTML2 = convertFromHTML(sample1);
        const state1 = ContentState.createFromBlockArray(
            contentHTML2.contentBlocks,
            contentHTML2.entityMap,
        );
        setContent(JSON.stringify(convertToRaw(state1)));
    }, [terms]);

    const save = (data) => {
        setAlert('Terms and Conditions are saved successfully', 'success');
        updateTermContent(termContent);
    };

    if (!isAdmin) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <section className="landing">
            <main className="main p-subscribe">
                <section className="thank-you" id="subscribe">
                    <div className="c-grid">
                        <div className="section__wrapper discount_table">
                            <div className="c-section" data-align="center" data-size="exbig">
                                <div className="c-section__head subscribe__box-title">
                                    Terms and Conditions
                                </div>
                                <div className="c-section__body">
                                    <ThemeProvider theme={myTheme}>
                                        <MUIRichTextEditor
                                            onChange = { value => {
                                                const textContent = stateToHTML(value.getCurrentContent())
                                                setTermContent(textContent);
                                                return textContent;
                                            }}
                                            value={content}
                                            onSave = {save}
                                        />
                                    </ThemeProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </section>
    );
}

Terms.propTypes = {
    updateTermContent: PropTypes.func.isRequired,
    getTermContent: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    terms: state.admin.terms,
});

export default connect(mapStateToProps, { updateTermContent, getTermContent, setAlert })(Terms);
