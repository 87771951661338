import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { setAlert } from '../../actions/alert';
import { getDiscountCodes, updateDiscountCode } from '../../actions/admin';

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

const DiscountCode = ({
    auth: { isAdmin }, discountPricings, discountFees, getDiscountCodes, updateDiscountCode, setAlert,
}) => {
    const [pricingRows, setPricingRows] = useState([]);
    const [feeRows, setFeeRows] = useState([]);

    useEffect(() => {
        document.title = 'Prospect IQ | Discount';
        getDiscountCodes();
    }, []);

    useEffect(() => {
        setPricingRows(discountPricings);
        setFeeRows(discountFees);
    }, [discountPricings, discountFees]);

    const handleProcessRowUpdateError = () => {
        setAlert('We cannot save the changes', 'danger');
    };
    const processRowUpdate = async (updatedRow) => {
        updateDiscountCode(updatedRow);
        return updatedRow;
    };

    if (!isAdmin) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <section className="landing">
            <main className="main p-subscribe">
                <section className="thank-you" id="subscribe">
                    <div className="c-grid">
                        <div className="section__wrapper discount_table">
                            <div className="c-section" data-align="center" data-size="medium">
                                <div className="c-section__head subscribe__box-title">
                                    Discount Code for Pricing
                                </div>
                                <div className="c-section__body">
                                    <div>
                                        <Box sx={{ height: 266, backgroundColor: 'white', textAlign: 'center' }}>
                                            <DataGrid
                                                rows={pricingRows.map((item, index) => ({ id: index + 1, ...item }))}
                                                columns={columns}
                                                hideFooter
                                                processRowUpdate={processRowUpdate}
                                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                            />
                                        </Box>
                                    </div>
                                </div>
                                
                                <br /><br />

                                <div className="c-section__head subscribe__box-title">
                                    Discount Code for Implementation Fee
                                </div>
                                <div className="c-section__body">
                                    <div>
                                        <Box sx={{ height: 266, backgroundColor: 'white', textAlign: 'center' }}>
                                            <DataGrid
                                                rows={feeRows.map((item, index) => ({ id: index + 1, ...item }))}
                                                columns={columns}
                                                hideFooter
                                                processRowUpdate={processRowUpdate}
                                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                            />
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </section>
    );
}

const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
        field: 'offRate',
        headerName: 'Discount Off',
        width: 120,
        editable: false,
    },
    {
        field: 'code',
        headerName: "Discount Code",
        width: 120,
        editable: true,
    }
];

DiscountCode.propTypes = {
    setAlert: PropTypes.func.isRequired,
    getDiscountCodes: PropTypes.func.isRequired,
    updateDiscountCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    discountPricings: state.admin.discountPricings,
    discountFees: state.admin.discountFees,
    auth: state.auth
});

export default connect(mapStateToProps, { getDiscountCodes, updateDiscountCode, setAlert })(DiscountCode);
