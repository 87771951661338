import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { verifyPayment } from '../../actions/payment';

const Success = ({
  auth: { user }, verifyPayment
}) => {

  useEffect(() => {
    document.title = 'Prospect IQ | Payment';
  }, []);

  useEffect(() => {
    if (user) verifyPayment(user.email);
  }, [user]);

  return (
    <section className="landing">
      <main className="main p-subscribe">
      <section class="thank-you" id="thank-you">
        <div class="c-grid" style={{ width: 'fit-content' }}>
          <div class="section__wrapper">
            <div class="c-section" data-align="center" data-size="">
              <div class="c-section__head">
                <div class="logo logo--header"><a class="logo__link" href="/"><img class="logo__image" src="./img/logo-2.png" srcSet="./img/logo-2@2x.png 2x" alt="Logotype" /></a></div>
              </div>
              <div class="c-section__body">
                <h5 class="c-section__title">Thank You</h5>
                <p class="c-section__desc">A customer success manager will call you within 24 hours! <br /><br />Or please schedule a kick-off call at a time that’s convenient for you!</p>
                <form class="c-form" action="" method="" autocomplete="off">
                  <div class="c-form__field c-form__field--btn">
                    <div class="c-btn__wrapper c-btn__wrapper--between">
                      <a class="c-btn" href="https://calendly.com/andrew-dorfman/prosepctiq-onboarding-with-customer-success" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half">Select Date & Time</a><a class="c-btn" href="https://prospectiq.ai/" data-btn-color="gray" data-btn-size="full" data-btn-fw="600" data-btn-round="half">Close</a>
                    </div>
                  </div>
                </form>
              </div>
              <div class="c-section__footer">
                <p class="c-section__info">Having trouble? Contact us at <a href='mailto:support@prospectiq.ai'>support@prospectiq.ai</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      </main>
    </section>
  );
};

Success.propTypes = {
  auth: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  verifyPayment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { verifyPayment })(Success);
