import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';

const Register = ({ setAlert, register, isAuthenticated, isRegistered, isVerified }) => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    password: '',
    password2: ''
  });

  const { fname, lname, email, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      register({ fname, lname, email, password });
    }
  };

  // if (isAuthenticated && isVerified) {
  //   return <Navigate to="/dashboard" />;
  // }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  // if (isRegistered) {
  //   if(isVerified == false) return <Navigate to = "/verify"/>
  //   else return <Navigate to="/dashboard" />
  // }

  return (
    <section className="container">
      <main className="main main--start p-sign-up">
      <section className="sign" id="sign">
      <div className="c-grid" style={{ width: 'fit-content' }}>
          <div className="section__wrapper">
            <div className="c-section" data-align="center">
              <div className="c-section__body">
                <h5 className="c-section__title">Sign up</h5>
                <p className="c-section__desc">Fill in your details below and continue signing up</p>
                <form className="c-form" onSubmit={onSubmit} autocomplete="off">
                  <div className="c-form__group">
                    <div>
                      <div className="c-form__field">
                        <label className="c-form__label" for="first_name">First name*</label>
                        <input className="c-form__input" type="text" name="fname" value={fname} id="fname" placeholder="Type your first name..." onChange={onChange} required />
                      </div>
                    </div>
                    <div>
                      <div className="c-form__field">
                        <label className="c-form__label" for="last_name">Last name*</label>
                        <input className="c-form__input" type="text" name="lname" id="lname" value={lname} placeholder="Type your last name..." onChange={onChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="c-form__field">
                    <label className="c-form__label" for="email">Email</label>
                    <input className="c-form__input" type="email" name="email" id="email" value={email} placeholder="Type your email address..." onChange={onChange} required />
                  </div>
                  <div className="c-form__group">
                    <div>
                      <div className="c-form__field">
                        <label className="c-form__label" for="password">Password</label>
                        <input className="c-form__input" type="password" name="password" id="password" value={password} onChange={onChange} placeholder="Type your password..." required />
                      </div>
                    </div>
                    <div>
                      <div className="c-form__field">
                        <label className="c-form__label" for="confirm_password">Confirm password*</label>
                        <input className="c-form__input" type="password" name="password2" id="password2" value={password2} onChange={onChange} placeholder="Retype your password..." required />
                      </div>
                    </div>
                  </div>
                  <div className="c-form__field c-form__field--btn">
                    <div className="c-btn__wrapper"><input className="c-btn" type="submit" value="Create account" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" /></div>
                  </div>
                  <p class="sign__link">Already have an account? <a href='/login'>Sign in</a></p>
                  <div className="c-form__field c-form__field--info">
                    <p className="c-form__info">By clicking “Create account” you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a></p>
                  </div>
                  
                </form>
              </div>
              <div className="c-section__footer">
                <p className="c-section__info">Having trouble? Contact us at <a href='mailto:support@prospectiq.ai'>support@prospectiq.ai</a></p>
              </div>
            </div>
          </div>
        </div>
        </section>
    </main>
    </section>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isRegistered: PropTypes.bool,
  isVerified: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isRegistered: state.auth.isRegistered,
  isVerified: state.auth.isVerified
});

export default connect(mapStateToProps, { setAlert, register })(Register);
