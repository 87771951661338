import {
    GETUSERDATA_SUCCESS,
    GETUSERDATA_FAIL,
    UPDATEUSERDATA_SUCCESS,
    GET_CUSTOMERDATA_SUCCESS,
    GET_CUSTOMERDATA_FAIL,
    UPDATE_CUSTOMERDATA_SUCCESS,
    DISCOUNT_SUCCESS,
    UPDATE_DISCOUNT_SUCCESS,
    TERMS_SUCCESS,
    LICENSE_SUCCESS
} from '../actions/types';

const initialState = {
    userData: [],
    userRow: null,
    customerData: [],
    customerRow: null,
    discountPricings: [],
    discountFees: [],
    discountRow: null,
    terms: "",
    license: "",
};

function adminReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GETUSERDATA_SUCCESS:
            return {
                ...state,
                userData: payload
            };
        case GETUSERDATA_FAIL:
            return {
                ...state,
                userData: []
            }
        case UPDATEUSERDATA_SUCCESS:
            for (var i = 0; i < state.userData.length; i++) {
                if (state.userData[i].email === payload.row.email) {
                    state.userData[i] = payload.row;
                    break;
                }
            }
            return {
                ...state
            }
        case GET_CUSTOMERDATA_SUCCESS:
            return {
                ...state,
                customerData: payload
            };
        case GET_CUSTOMERDATA_FAIL:
            return {
                ...state,
                customerData: []
            }
        case UPDATE_CUSTOMERDATA_SUCCESS:
            for (i = 0; i < state.customerData.length; i++) {
                if (state.customerData[i].email === payload.row.email) {
                    state.customerData[i] = payload.row;
                    break;
                }
            }
            return {
                ...state
            }
        case DISCOUNT_SUCCESS:
            return {
                ...state,
                discountPricings: payload.pricings,
                discountFees: payload.fees,
            }
        case UPDATE_DISCOUNT_SUCCESS:
            return {
                ...state,
                discountRow: payload
            }
        case TERMS_SUCCESS:
            return {
                ...state,
                terms: payload
            }
        case LICENSE_SUCCESS:
            return {
                ...state,
                license: payload
            }
        default:
            return state;
    }
}

export default adminReducer;
