import api from '../utils/api';
import {
    PAYMENT_TITLE,
    PAYMENT_FAIL,
    PAYMENT_COST,
} from './types';

export const payment = (title, cost = '') => async (dispatch) => {
  try {
    const numCost = cost === '' ? 0 : parseInt(cost);
    const req = {
      planNo: title,
      cost: numCost,
    }

    const res = await api.post('/payment', req);
    const { success, paymentUrl } = res.data;
    if (success) {
      // Redirect to the Stripe payment page
      window.location.href = paymentUrl;
    }
  } catch (err) {
    dispatch({
      type: PAYMENT_FAIL
    });
  }
};

export const checkout = (title, qty = '') => async (dispatch) => {
  try {
    const numQty = qty === '' ? 0 : parseInt(qty);
    const req = {
      planNo: title,
      qty: numQty,
    }

    const res = await api.post('/payment/checkout', req);
    const { success, paymentUrl } = res.data;
    if (success) {
      // Redirect to the Stripe payment page
      window.location.href = paymentUrl;
    }
  } catch (err) {
    dispatch({
      type: PAYMENT_FAIL
    });
  }
};

export const paymentRegister = (title, cost = '') => async (dispatch) => {
  dispatch({
    type:PAYMENT_TITLE,
    payload: title,
  });
  dispatch({
    type: PAYMENT_COST,
    payload: cost,
  });
};

export const verifyPayment = (email) => async (dispatch) => {
  try {
    const req = {
      email: email
    }

    await api.post('/payment/verify', req);
  } catch (err) {
    dispatch({
      type: PAYMENT_FAIL
    });
  }
};
