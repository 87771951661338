import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { payment } from '../../actions/payment';
import { submitFeeDiscount } from '../../actions/auth';
import { setAlert } from '../../actions/alert';
import { useNavigate } from 'react-router-dom';

let templateString = [
    "Initial system set-up",
    "ICP Session and initial download of 1000 names",
    "AI processing of names for pickup patterns",
    "AI optimization of script(s).",
    "Training and support"
]

const ImplementationFee = ({
    auth: { user }, payment, paymentTitle, submitFeeDiscount
}) => {

    if (paymentTitle === 'Standard Individual Contributor') {
        templateString = [
            "Month to Month Contract ",
            "Monthly Seat License",
            "Enhanced ICP Leads Provided",
            "IQ Connect: AI Reach Algorithm ",
            "IQ Perfect Pitch: AI Script Optimization ",
            "IQ Nurture: AI Reminder and Reschedule Cadence ",
            "Caller/Manager Dashboard",
            "Free Dialer",
            "Free Local Presence Phone Calls",
            "Technical Customer Support and Training",
            "*Annual Contract (20% Discount)",
        ];
    } else if (paymentTitle === 'Standard Enterprise') {
        templateString = [
            "Month to Month Contract ",
            "Monthly Seat License",
            "Enhanced ICP Leads Provided",
            "IQ Connect: AI Reach Algorithm ",
            "IQ Perfect Pitch: AI Script Optimization ",
            "IQ Nurture: AI Reminder and Reschedule Cadence ",
            "Caller/Manager Dashboard",
            "Free Dialer",
            "Free Local Presence Phone Calls",
            "Technical Customer Support and Training",
            "*Annual Contract (20% Discount)",
            "Manager Dashboard",
            "Team Reporting",
            "Team Training/Onboarding Module",
            "Advanced Statistical Conversion Analyses",

        ];
    } else if (paymentTitle === 'Specialty Individual Contributor') {
        templateString = [
            "Month to Month Contract ",
            "Monthly Seat License",
            "Enhanced ICP Leads Provided",
            "IQ Connect: AI Reach Algorithm ",
            "IQ Perfect Pitch: AI Script Optimization ",
            "IQ Nurture: AI Reminder and Reschedule Cadence ",
            "Caller/Manager Dashboard",
            "Free Dialer",
            "Free Local Presence Phone Calls",
            "Technical Customer Support and Training",
            "*Annual Contract (20% Discount)",
        ];
    } else if (paymentTitle === 'Speciality Enterprise') {
        templateString = [
            "Month to Month Contract ",
            "Monthly Seat License",
            "Enhanced ICP Leads Provided",
            "IQ Connect: AI Reach Algorithm ",
            "IQ Perfect Pitch: AI Script Optimization ",
            "IQ Nurture: AI Reminder and Reschedule Cadence ",
            "Caller/Manager Dashboard",
            "Free Dialer",
            "Free Local Presence Phone Calls",
            "Technical Customer Support and Training",
            "*Annual Contract (20% Discount)",
            "Manager Dashboard",
            "Team Reporting",
            "Team Training/Onboarding Module",
            "Advanced Statistical Conversion Analyses",
        ];
    }

    const [codeFee, setCodeFee] = useState('');
    useEffect(() => {
        document.title = 'Prospect IQ | Implementation Fee';
    }, []);

    const navigate = useNavigate();
    const clickContinue = async () => {
        // navigate('/maxspend');
        let realTitle = user.planNo;
        if (paymentTitle !== '') realTitle = paymentTitle;

        if (realTitle !== '') {
            if (codeFee !== '') await submitFeeDiscount(user.email, codeFee);
            payment(realTitle);
          }
    
    };

    const onChange = (e) => {
        setCodeFee(e.target.value);
    }

    return (
        <section className="landing">
            <main className="main p-subscribe">
                <section class="thank-you" id="thank-you">
                    <div class="c-grid" style={{ width: '50%' }}>
                        <div class="section__wrapper">
                            <div class="c-section" data-align="center" data-size="xmedium">
                                <div class="c-section__head">
                                    <div class="logo logo--header">
                                        <a class="logo__link" href="/">
                                            <img class="logo__image" src="../img/logo-2.png" srcSet="../img/logo-2@2x.png 2x" alt="Logotype" />
                                        </a>
                                    </div>
                                </div>
                                <div class="c-section__body">
                                    <p className="subscribe__box-title"><center className="feeClass">Implementation Fee</center></p>
                                    <p style={{ fontSize: '16px', textAlign: 'center' }}>One Time Implementation Fee $500. Includes the Following:</p>
                                    <br />
                                    <p>
                                        <ol style={{ listStyle: 'disc' }}>
                                            { templateString.map((item, index) => (
                                                <li key = {index}>{item}</li>
                                            )) }
                                        </ol>
                                    </p>
                                    <br />
                                    <br />
                                    <form class="c-form" autocomplete="off">
                                        <div className="c-form__field">
                                            <label
                                                className="c-form__label"
                                                for="discountFee"
                                                data-size="full"
                                                data-width="600">Optional AE Code 1</label>
                                            <input
                                                className="c-form__input"
                                                type="string"
                                                name="discountFee"
                                                id="discountFee"
                                                data-size="full"
                                                data-width="600"
                                                placeholder="Please input your Optional AE Code 1" value={codeFee} onChange={onChange} />
                                        </div>
                                        <div className="c-form__field c-form__field--btn">
                                            <div className="c-btn__wrapper">
                                                <input
                                                    type="button"
                                                    style={{ textAlign: 'center' }}
                                                    className="c-btn"
                                                    data-btn-color="blue"
                                                    data-btn-size="full"
                                                    data-btn-fw="600"
                                                    data-btn-round="half"
                                                    onClick={clickContinue}
                                                    value="Continue" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </section>
    );
};

ImplementationFee.propTypes = {
    auth: PropTypes.object.isRequired,
    submitFeeDiscount: PropTypes.func.isRequired,
    payment: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    paymentTitle: state.auth.paymentTitle,
});

export default connect(mapStateToProps, { setAlert, payment, submitFeeDiscount })(ImplementationFee);
