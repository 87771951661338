import {
  REGISTER_SUCCESS,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  CUSTOMER_LOADED,
  ADMIN_LOADED,
  LOGOUT,
  ACCOUNT_DELETED,
  VERIFY_SUCCESS,
  PAYMENT_SUCCESS,
  PAYMENT_TITLE,
  PAYMENT_COST,
} from '../actions/types';

import setAuthToken from '../utils/setAuthToken';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  loading: true,
  user: null,
  isRegistered: false,
  isVerified: false,
  isSubscribed: false,
  isAdmin: false,
  paymentTitle: '',
  cost: '',
  isDiscountFeeError: false,
  isDiscountPricingError: false,
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CUSTOMER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload
      };
    case ADMIN_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        isAdmin: true,
        user: payload
      };
    case REGISTER_SUCCESS:
      setAuthToken(payload.token);
      return {
        ...state,
        isRegistered: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerified: true,
        isRegistered: false,
      }
    case ACCOUNT_DELETED:
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isVerified: false,
        loading: false,
        user: null,
        isAdmin: false,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        isSubscribed: true,
      };
    case PAYMENT_TITLE:
      return {
        ...state,
        paymentTitle: payload,
      };
    case PAYMENT_COST:
      return {
        ...state,
        cost: payload,
      };
    default:
      return state;
  }
}

export default authReducer;
