import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import VerificationCode from './components/auth/VerificationCode';
import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';
import Success from './components/stripe/Success';
import SuccessWithoutAuth from './components/stripe/SuccessWithoutAuth';
import ImplementationFee from './components/stripe/ImplementationFee';
import PrivateRoute from './components/routing/PrivateRoute';
import { LOGOUT } from './actions/types';
import MaxSpend from './components/stripe/MaxSpend';
import DiscountCode from './components/admin/DiscountCode';
import AdminCustomerTable from './components/dashboard/AdminCustomerTable';
import Terms from './components/admin/Terms';
import License from './components/admin/License';
// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';

const App = () => {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Alert />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="resetpassword/:token" element={<ResetPassword />} />
          <Route path='verify' element={<VerificationCode />} />
          <Route path='schedule_appointment' element={<SuccessWithoutAuth />} />

          <Route path='maxspend' element={<PrivateRoute component={MaxSpend} />} />
          <Route path='implementationfee' element={<PrivateRoute component={ImplementationFee} />} />
          <Route path="dashboard" element={<PrivateRoute component={Dashboard} />} />
          <Route path="success" element={<PrivateRoute component={Success} />} />
          <Route path="discountcode" element={<PrivateRoute component={DiscountCode} />} />
          <Route path='terms' element = { <PrivateRoute component={Terms} /> } />
          <Route path='license' element = { <PrivateRoute component={License} /> } />
          <Route path='customer' element = {<PrivateRoute component={AdminCustomerTable} />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
