import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { logout, submitPricingDiscount, submitFeeDiscount } from '../../actions/auth';
import { setAlert } from '../../actions/alert';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Navbar = ({ auth: { user, isAuthenticated, isAdmin }, logout, setAlert, submitPricingDiscount, submitFeeDiscount }) => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [formData, setFormData] = useState({
    codePricing: '',
    codeFee:  '',
  });

  const { codePricing, codeFee } = formData;
  const [showMobileContent, setShowMobileContent] = useState(false);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    if (codePricing !== '') submitPricingDiscount(user.email, codePricing);
    if (codeFee !== '') submitFeeDiscount(user.email, codeFee);
    handleClose();
  }

  const mobileContent = (
    <div mobile-block-js='true' className={`${showMobileContent ? 'is-open' : 'empty-class'}`}>
      <nav className="header__nav">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <form class="c-form" autocomplete="off" onSubmit={onSubmit}>
                <div className="c-form__field">
                  <label className="c-form__label" for="email">Discount Pricing Code</label>
                  <input className="c-form__input" type="string" name="codePricing" id="codePricing" placeholder="Type your discount code for pricing here..." value={codePricing} onChange={onChange} /> <br />
                  {/* <label className="c-form__label" for="email">Discount Implementation Fee Code</label>
                  <input className="c-form__input" type="string" name="codeFee" id="codeFee" placeholder="Type your discount code for implementation fee here..." value={codeFee} onChange={onChange} /> */}
                </div>
                <div className="c-form__field c-form__field--btn">
                  <div className="c-btn__wrapper">
                    <input className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" type="submit" value="Submit" />
                  </div>
                </div>
              </form>
            </Typography>
          </Box>
        </Modal>

        {user ? (isAdmin ?
          (<>
            <a href="/dashboard">Clients</a>
            <a href="/customer">Customers</a>
            <a href="/discountcode">Discount Code</a>
            <a href='/terms'>Terms & Conditions</a>
            <a href='/license'>License Agreement</a>
          </>) :
          (<>
            {/* <a href="/dashboard">Standard Individual Contributor</a>
            <a href="/dashboard">Standard Enterprise</a>
            <a href="/dashboard">Specialty Individual Contributor </a>
            <a href="/dashboard">Speciality Enterprise</a> */}
            <a href="/dashboard">Pricing</a>
            <a href="/dashboard">About</a>
            <a href='#' onClick={handleOpen}>Discount</a>
          </>))
          : <a></a>}
      </nav>
    </div>
  );

  const handleMobileContent = (e) => {
    const elem = e.target;
    setShowMobileContent(!showMobileContent);
  }

  const authLinks = (
    <nav className="header" id="header">
      <div className="header__wrapper">
        <div>
          <div className="logo logo--header"><a className="logo__link" href="/">
            <img className="logo__image" src="/img/logo.png" srcSet="/img/logo@2x.png 2x" alt="Logotype" /></a></div>
        </div>
        {mobileContent}
        <div>
          {!isAdmin && user !== null && user.discountCode.pricing !== '0%' ?
            (<div>
              <nav className="header__nav">Discount rate for pricing : {user.discountCode.pricing} &nbsp; </nav>
            </div>) :
            (<div></div>)
          }
          <div className="header__btn c-btn__wrapper"><a className="c-btn" onClick={logout} href="#!">Logout</a></div>
          <div className={`${showMobileContent ? 'hamburger hamburger--squeeze is-active' : 'hamburger hamburger--squeeze'}`} role="button" hamburger-js="hamburger-js" onClick={handleMobileContent}>
            <div className="hamburger-box">
              <div className="hamburger-inner">
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );

  const guestLinks = (
    <nav className="header" id="header">
      <div className="header__wrapper">
        <div>
          <div className="logo logo--header"><a className="logo__link" href="/">
            <img className="logo__image" src="/img/logo.png" srcSet="/img/logo@2x.png 2x" alt="Logotype" /></a></div>
        </div>
        {mobileContent}
        <div>
          <div className="header__btn c-btn__wrapper"><a className="c-btn" href="/login">Log in</a></div>
          <div className={`${showMobileContent ? 'hamburger hamburger--squeeze is-active' : 'hamburger hamburger--squeeze'}`} role="button" hamburger-js="hamburger-js" onClick={handleMobileContent}>
            <div className="hamburger-box">
              <div className="hamburger-inner"></div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );

  return (
    <nav className="navbar bg-dark">
      <Fragment>{(isAuthenticated) ? authLinks : guestLinks}</Fragment>
      {/* <Fragment>{(isAuthenticated && isVerified === true) ? authLinks : guestLinks}</Fragment> */}
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  submitPricingDiscount: PropTypes.func.isRequired,
  submitFeeDiscount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout, setAlert, submitPricingDiscount,submitFeeDiscount })(Navbar);
