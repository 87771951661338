import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { payment } from '../../actions/payment';
import CustomerDashboard from './CustomerDashboard';
import AdminDashboard from './AdminDashboard';

const Dashboard = ({
  auth: { user, isAdmin }
}) => {
  return (
    isAdmin === true ? <AdminDashboard /> : <CustomerDashboard />
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {payment})(Dashboard);
