import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';

const Login = ({ login, isAuthenticated, isVerified }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated === true && isVerified === true) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section className="landing">
      <main className="main main--start p-sign-in">
        <section className="sign" id="sign">
          <div className="c-grid" style={{ minWidth: 'fit-content', width: '40%' }}>
            <div className="section__wrapper">
              <div className="c-section" data-align="center">
                <div className="c-section__body">
                  <h5 className="c-section__title">Log in to your account</h5>
                  
                  {/* <div className="sign__with c-btn__wrapper c-btn__wrapper--column"><a className="c-btn c-btn--with" href="#" data-btn-round="half">
                    <img src="./img/icon-google.png" srcset="./img/icon-google@2x.png 2x" alt="" />
                    <span>Log in with Google</span></a><a className="c-btn c-btn--with" href="#" data-btn-round="half">
                      <img src="./img/icon-sso.png" srcset="./img/icon-sso@2x.png 2x" alt="" /><span>Log in with SSO</span></a></div>
                  <div className="sign__or"><span>or</span></div> */}
              
                  <form className="c-form" autoComplete="off" onSubmit={onSubmit}>
                    <div className="c-form__field">
                      <label className="c-form__label" htmlFor="email">Email</label>
                      <input className="c-form__input" type="email" name="email" id="email" placeholder="Type your email address..." value={email} onChange={onChange} required />
                    </div>
                    <div className="c-form__field">
                      <label className="c-form__label" htmlFor="password">Password</label>
                      <input className="c-form__input" type="password" name="password" id="password" placeholder="Type your password..." value={password} onChange={onChange} minLength="6" required />
                    </div>
                    <div className="c-form__field c-form__field--link"><a href="/forgotpassword">Forgot password?</a></div>
                    <div className="c-form__field c-form__field--btn">
                      <div className="c-btn__wrapper"><input className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" type="submit" value="Log in" /></div>
                    </div>
                  </form>
                  <p className="sign__link">Don't have an account? <a href="/register">Sign up</a></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer" id="footer">
        <div className="c-grid">
          <div className="footer__wrapper"></div>
        </div>
      </footer>
    </section>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isVerified: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVerified: state.auth.isVerified
});

export default connect(mapStateToProps, { login })(Login);
