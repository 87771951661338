import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateLicenseAgreement, updateTermCondition } from '../../actions/auth';
import { paymentRegister, checkout } from '../../actions/payment';
import { setAlert } from '../../actions/alert';
import { getTermContent, getLicenseAgreement } from '../../actions/admin';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: 'fit-content',
  bgcolor: 'background.paper',
  borderRadius: '1rem',
  boxShadow: 24,
  p: 4,
};

const CustomerDashboard = ({
  auth: { user }, paymentRegister, checkout, updateLicenseAgreement, updateTermCondition, setAlert, getTermContent, getLicenseAgreement, terms, license
}) => {

  useEffect(() => {
    document.title = 'Prospect IQ | Dashboard';
    getTermContent();
    getLicenseAgreement();
  }, []);

  // const [selectedOption, setSelectedOption] = useState('125');
  // const [selectedBoostOption, setSelectedBoostOption] = useState('25');
  const [licenseAgreement, setLicenseAgreement] = useState(user.licenseAgreement);
  const [termCondition, setTermCondition] = useState(user.termCondition);

  const [isLicenseClicked, setIsLicenseClicked] = useState(false);
  const [isTermClicked, setIsTermClicked] = useState(false);
  const [creditCount, setCreditCount] = useState(1)
  
  const [licenseOpen, setLicenseOpen] = useState(false);
  const handleLicenseOpen = () => setLicenseOpen(true);
  const handleLicenseClose = () => setLicenseOpen(false);
  
  const [boostIQInputOpen, setBoostIQInputOpen] = useState(false);
  const handleBoostIQInputOpen = () => setBoostIQInputOpen(true);
  const handleBoostIQInputClose = () => setBoostIQInputOpen(false);

  const [termOpen, setTermOpen] = useState(false);
  const handleTermOpen = () => setTermOpen(true);
  const handleTermClose = () => setTermOpen(false);

  const [termContent, setTermContent] = useState('');
  const [licenseContent, setLicenseContent] = useState('');

  useEffect(() => { setTermContent(terms); }, [terms]);
  useEffect(() => { setLicenseContent(license); }, [license]);

  // function onValueChange(event) {
  //   setSelectedOption(event.target.value);
  // }

  // function onBoostValueChange(event) {
  //   setSelectedBoostOption(event.target.value);
  // }

  const targetRef = useRef();
  const navigate = useNavigate();

  const clickContinueCheckout = async () => {
    handleBoostIQInputClose();
    await checkout('BoostIQ', creditCount);
    console.log('Checking out...', creditCount);
  }

  const clickHandler = async (title) => {
    if (licenseAgreement !== true || termCondition !== true) {
      setIsLicenseClicked(true);
      setIsTermClicked(true);
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    await paymentRegister(title);

    navigate('/implementationfee');
    // navigate('/maxspend');
  }

  const handleLicenseAgreement = () => {
    setLicenseAgreement(!licenseAgreement);
    setAlert('You agreed to the license agreement.', 'success');
    updateLicenseAgreement(user.email, !licenseAgreement);
  }

  const handleTermCondition = () => {
    setTermCondition(!termCondition);
    setAlert('You agreed to the terms & conditions.', 'success');
    updateTermCondition(user.email, !termCondition);
  }

  const clickLicenseAccept = () => {
    setLicenseAgreement(true);
    setAlert('You agreed to the license agreement.', 'success');
    updateLicenseAgreement(user.email, true);
    handleLicenseClose();
  }

  const clickLicenseDecline = () => {
    setLicenseAgreement(false);
    updateLicenseAgreement(user.email, false);
    handleLicenseClose();
  }

  const clickTermAccept = () => {
    setTermCondition(true);
    setAlert('You agreed to the terms & conditions.', 'success');
    updateTermCondition(user.email, true);
    handleTermClose();
  }

  const clickTermDecline = () => {
    setTermCondition(false);
    updateTermCondition(user.email, false);
    handleTermClose();
  }

  return (
    <section className="landing">
      <main className="main p-subscribe">
        <Modal
          open={licenseOpen}
          onClose={handleLicenseClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div dangerouslySetInnerHTML={{__html: licenseContent}} style = {{'overflow-y': 'auto', 'maxHeight': '500px'}}/>
              <br />
              <button 
                className="c-btn left-btn" 
                data-btn-color={ licenseAgreement ? "gray" : "blue" } 
                data-btn-size="half" 
                data-btn-fw="300" 
                data-btn-round="half" 
                onClick={ clickLicenseAccept }
                disabled= {licenseAgreement}
              >Accept</button>
              <button
                className="c-btn right-btn"
                data-btn-color="gray"
                data-btn-size="half"
                data-btn-fw="300"
                data-btn-round="half"
                onClick={ clickLicenseDecline }
                disabled= {licenseAgreement}
              > Decline</button>
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={termOpen}
          onClose={handleTermClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div dangerouslySetInnerHTML={{__html: termContent}} style = {{'overflow-y': 'auto', 'maxHeight': '500px'}}/>
              <br />
              <button
                className="c-btn left-btn"
                data-btn-color={ termCondition ? "gray" : "blue" }
                data-btn-size="half"
                data-btn-fw="300"
                data-btn-round="half"
                onClick={ clickTermAccept }
                disabled={ termCondition }
              >Accept</button>
              <button
                className="c-btn right-btn"
                data-btn-color="gray"
                data-btn-size="half"
                data-btn-fw="300"
                data-btn-round="half"
                onClick={ clickTermDecline }
                disabled={ termCondition }
              > Decline</button>
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={boostIQInputOpen}
          onClose={handleBoostIQInputClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form className="c-form" autoComplete="off">
              <div className="c-form__field">
                  <label
                      className="c-form__label"
                      htmlFor="creditsNum"
                      data-size="full"
                      data-width="600">How many credits would you like to purchase?</label>
                  <p className='subscribe__box-desc'>Each credit is equal to 1 lead processed</p>
                  <input
                      className="c-form__input"
                      type="string"
                      name="creditsNum"
                      id="creditsNum"
                      data-size="full"
                      data-width="600"
                      placeholder="Please enter # of credits here" value={creditCount} onChange={(e) => setCreditCount(e.target.value)} />
              </div>
              <div className="c-form__field c-form__field--btn">
                  <div className="c-btn__wrapper">
                      <input
                          type="button"
                          style={{ textAlign: 'center' }}
                          className="c-btn"
                          data-btn-color="blue"
                          data-btn-size="full"
                          data-btn-fw="600"
                          data-btn-round="half"
                          onClick={clickContinueCheckout}
                          value="Continue" />
                  </div>
              </div>
            </form>
          </Box>
        </Modal>
        <section className="subscribe" id="subscribe">
          <div className="c-grid">
            <div className="section__wrapper">
              <div className="c-section" data-align="center" data-size="exbig">
                <div className="c-section__head">
                  <div className="logo logo--header"><a className="logo__link" href="/"><img className="logo__image" src="./img/logo-2.png" srcSet="./img/logo-2@2x.png 2x" alt="Logotype" /></a></div>
                </div>
                <div className="c-section__body">
                  <h5 className="c-section__title">AccelerateIQ Pricing</h5>
                  <p>Give your outbound callers the AccelerateIQ Advantage. Reach and book appointments at scale!</p>
                  <div className="subscribe__box-wrapper">
                    <div className="subscribe__box">
                      <div>
                        <p className="subscribe__box-title">Standard Individual Contributor</p>
                        <p className="subscribe__box-subtitle">
                          <br/>$99<br/>
                          {/* License Fee<br/> */}
                          Per Month</p>
                      </div>
                      <div className="c-btn__wrapper"><button className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" onClick={() => { clickHandler('Standard Individual Contributor') }}>Choose</button></div>
                    </div>
                    <div className="subscribe__box">
                      <div>
                        <p className="subscribe__box-title">Standard Enterprise</p>
                        <div className="subscribe__box-subtitle-wrapper">
                          <div>
                            <div>
                              <p className="subscribe__box-subtitle">
                              <br/>$275<br/>
                              {/* License Fee<br/> */}
                              Per Seat, Per Month
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="c-btn__wrapper"><button className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" onClick={() => { clickHandler('Standard Enterprise') }}>Choose</button></div>
                    </div>
                    <div className="subscribe__box">
                      <div className="flex-with-gap">
                        <p className="subscribe__box-title">Specialty Individual Contributor</p>
                        <p className="subscribe__box-desc">(Insurance, RIA Recruitment, Financial Advsior)</p>
                        <p className="subscribe__box-subtitle">
                          $800<br/>
                          {/* License Fee<br/> */}
                          Per Month</p>
                        <p className="subscribe__box-desc"></p>
                      </div>
                      <div className="c-btn__wrapper"><button className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" onClick={() => { clickHandler('Specialty Individual Contributor') }}>Choose</button></div>
                    </div>
                    <div className="subscribe__box">
                      <div className="flex-with-gap">
                        <p className="subscribe__box-title">Specialty Enterprise</p>
                        <p className="subscribe__box-desc">(Insurance, RIA Recruitment, Financial Advisor)</p>
                        <div className="subscribe__box-subtitle-wrapper">
                          <div>
                            <div>
                              <p className="subscribe__box-subtitle">
                                $1200<br/>
                                Per Seat, Per Month
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="c-btn__wrapper"><button className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" onClick={() => { clickHandler('Specialty Enterprise') }}>Choose</button></div>
                    </div>
                    <div className="subscribe__box">
                      <div className="flex-with-gap">
                        <p className="subscribe__box-title">BoostIQ</p>
                        <p className="subscribe__box-subtitle">$1 Per Lead</p>
                        <p>Volume discounts available</p>
                        <div>
                          <ul className='text-left text-small px-2'>
                            <li className='list-style-disc'>$1 Per Lead up to 2,500 Leads</li>
                            <li className='list-style-disc'>10% Discount for 2,501-5,000 Leads</li>
                            <li className='list-style-disc'>20% Discount for 5,001+ Leads</li>
                          </ul>
                        </div>
                      </div>
                      <div className="c-btn__wrapper"><button className="c-btn" data-btn-color="blue" data-btn-size="full" data-btn-fw="600" data-btn-round="half" onClick={() => { handleBoostIQInputOpen() }}>Choose</button></div>
                    </div>
                  </div>
                  <p ref={targetRef}>
                    <input
                      type="checkbox"
                      checked={licenseAgreement}
                      onChange={handleLicenseAgreement}
                      className={!licenseAgreement && isLicenseClicked ? 'unchecked' : 'checked'}
                      disabled={licenseAgreement}
                    />
                    <span style={{ color: 'red', margin: '0 1px 0 5px' }}>*</span>
                    I have read and agree to the terms of the <u><a href='#' onClick={handleLicenseOpen}>license agreement</a>.</u>
                  </p>
                  <p>
                    <input
                      type="checkbox"
                      checked={termCondition}
                      onChange={handleTermCondition}
                      className={!termCondition && isTermClicked ? 'unchecked' : 'checked'}
                      disabled={termCondition}
                    />
                    <span style={{ color: 'red', margin: '0 1px 0 5px' }}>*</span>
                    I have read and accept the <u><a href='#' onClick={handleTermOpen}>terms & conditions</a>.</u>
                  </p>
                </div>
                <div className="c-section__footer">
                  <p className="c-section__info">Having trouble? Contact us at <a href='mailto:support@prospectiq.ai'>support@prospectiq.ai</a></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </section>
  );
};

CustomerDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  paymentRegister: PropTypes.func.isRequired,
  checkout: PropTypes.func.isRequired,
  updateTermCondition: PropTypes.func.isRequired,
  updateLicenseAgreement: PropTypes.func.isRequired,
  getTermContent: PropTypes.func.isRequired,
  getLicenseAgreement: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  terms: state.admin.terms,
  license: state.admin.license,
});

export default connect(mapStateToProps, { paymentRegister, checkout, updateLicenseAgreement, updateTermCondition, setAlert, getTermContent, getLicenseAgreement })(CustomerDashboard);
