import api from '../utils/api';
import { setAlert } from './alert';

import {
    GETUSERDATA_SUCCESS,
    GETUSERDATA_FAIL,
    UPDATEUSERDATA_SUCCESS,
    UPDATEUSERDATA_FAIL,
    GET_CUSTOMERDATA_SUCCESS,
    GET_CUSTOMERDATA_FAIL,
    UPDATE_CUSTOMERDATA_SUCCESS,
    UPDATE_CUSTOMERDATA_FAIL,
    DISCOUNT_SUCCESS,
    DISCOUNT_FAIL,
    UPDATE_DISCOUNT_SUCCESS,
    TERMS_SUCCESS,
    LICENSE_SUCCESS
} from './types';

export const getUserData = (date) => async (dispatch) => {
    try {
        const params = { date: date };
        const res = await api.get('/users', { params });
        dispatch({
            type: GETUSERDATA_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GETUSERDATA_FAIL
        });
    }
    return [];
};

export const updateUserData = (row, dateValue) => async (dispatch) => {
    let res;
    try {
        const req = {
            data: row,
            date: dateValue.format('YYYY-MM-DD'),
        }
        res = await api.post('/users/update', req);

        if (res.data.row) {
            dispatch({
                type: UPDATEUSERDATA_SUCCESS,
                payload: res.data
            });
        }
    } catch (err) {
        dispatch({
            type: UPDATEUSERDATA_FAIL
        });
    }
};

export const getCustomerData = (date) => async (dispatch) => {
    try {
        const params = { date: date };
        console.log('get customer data at ', date);
        const res = await api.get('/customers', { params });
        dispatch({
            type: GET_CUSTOMERDATA_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_CUSTOMERDATA_FAIL
        });
    }
    return [];
};

export const updateCustomerData = (row, dateValue) => async (dispatch) => {
    let res;
    try {
        const req = {
            data: row,
            date: dateValue.format('YYYY-MM-DD'),
        }
        res = await api.post('/customers/update', req);

        if (res.data) {
            console.log('response data for Update Customer Data', res.data);
            dispatch({
                type: UPDATE_CUSTOMERDATA_SUCCESS,
                payload: res.data
            });
        }
    } catch (err) {
        dispatch({
            type: UPDATE_CUSTOMERDATA_FAIL
        });
    }
};

// getDiscountCodes, updateDiscountCode
export const getDiscountCodes = () => async (dispatch) => {
    let res;
    try {

        res = await api.get('/discount');

        if (res.data) {
            dispatch({
                type: DISCOUNT_SUCCESS,
                payload: res.data
            });
        }
    } catch (err) {
        dispatch({
            type: DISCOUNT_FAIL
        });
    }
};

export const updateDiscountCode = (row) => async (dispatch) => {
    let res;
    try {
        const req = {
            offRate: row.offRate,
            code: row.code,
        }
        res = await api.post('/discount', req);

        if (res.data.row) {
            dispatch({
                type: UPDATE_DISCOUNT_SUCCESS,
                payload: res.data.row
            });
        }
    } catch (err) {
        dispatch({
            type: DISCOUNT_FAIL
        });
    }
};

export const getTermContent = () => async (dispatch) => {
    let res;
    try {
        res = await api.get('/term');

        dispatch({
            type: TERMS_SUCCESS,
            payload: res.data.content
        });
    } catch (err) {
        dispatch({
            type: DISCOUNT_FAIL
        });
    }
};

export const updateTermContent = (content) => async (dispatch) => {
    try {
        const req = {
            content: content,
        }
        await api.post('/term', req);

    } catch (err) {
    }
};

export const getLicenseAgreement = () => async (dispatch) => {
    let res;
    try {
        res = await api.get('/license');

        dispatch({
            type: LICENSE_SUCCESS,
            payload: res.data.content
        });
    } catch (err) {
        dispatch({
            type: DISCOUNT_FAIL
        });
    }
};

export const updateLicenseAgreement = (content) => async (dispatch) => {
    try {
        const req = {
            content: content,
        }
        await api.post('/license', req);

    } catch (err) {
    }
};

export const chargeClient = (email, cost, date, isClient) => async (dispatch) => {
    try {
        const req = {
            email: email,
            cost: cost,
            date: date,
            isClient: isClient,
        }
        const res = await api.post('/payment/charge', req);
        
        if (res.data.success === true) {
            dispatch(setAlert('The invoice is charged successfully.', 'success'));
            const params = { date: date.format('YYYY-MM-DD') };
            const res = await api.get('/customers', { params });

            console.log(res.data);
            dispatch({
                type: GET_CUSTOMERDATA_SUCCESS,
                payload: res.data
            });
        }
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
        }
    }
};
